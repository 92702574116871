import {useQuestionContext} from "../../context/QuestionsContext";

export const QuestionText = props => {
    const {comment, setComment} = useQuestionContext();
    const {next, isSaving} = useQuestionContext();
    return (
        <>
            <div className="questionContainer"><h1>{props.question.text}</h1>
                <textarea
                    className="textInput"
                    type='text'
                    placeholder={props.question.placeholder}
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}/>
            </div>
            <div className="footer">
                <div className="button-fixed clickable" onClick={() => {
                    if(!isSaving) next(props.question, null, comment);
                }}>
                    <p>Submit</p>
                </div>
            </div>
        </>
    )
}
