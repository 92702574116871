import {createContext, useContext, useEffect, useState} from "react";
import { browserName, osName } from "react-device-detect";
import dayjs from "dayjs";

import axios from 'axios';

export const QuestionsContext = createContext({
    questions: [],
    questionNumber: 0,
    responses: {}
});

const BASE_URL =
    window.location.href.indexOf('localhost') > -1
    ? 'https://app-burst-prod-east.azurewebsites.net/'
    : window.location.href.indexOf('dev') > -1
    ? 'https://api-burst-api-dev-east.azurewebsites.net/'
    : window.location.href.indexOf('letsburst.com') > -1
    ? 'https://app-burst-prod-east.azurewebsites.net/'
    : 'https://api-burst-api-dev-east.azurewebsites.net/';
const defaultQuestions = [
    {
        id: 0,
        type: 'welcome',
        name: 'first',
        isQuestion: false,
        responses: [],
    },
    {
        id: 2,
        type: 'select',
        idExperience: 2818,
        isQuestion: true,
        questionNumber: 1,
        options: [],
        responses: [],
    },
    {
        id: 1,
        idExperience: 2814,
        type: 'radio',
        isQuestion: true,
        questionNumber: 2,
        label: 'What did you think of this month’s pack?',
        options: [],
        responses: [],
    },
    {
        id: 2,
        type: 'checkbox',
        idExperience: 2815,
        isQuestion: true,
        questionNumber: 3,
        forkFor: 2814,
        forkValues: [4,3],
        options: [],
        responses: [],
    },
    {
        id: 2,
        type: 'checkbox',
        idExperience: 2819,
        isQuestion: true,
        questionNumber: 3,
        forkFor: 2814,
        forkValues: [2,1,0],
        options: [],
        responses: [],
    },
    {
        id: 3,
        type: 'checkbox',
        name: 'second',
        isQuestion: true,
        idExperience: 2816,
        questionNumber: 4,
        label: 'What types of things would you like to see more of?',
        options: [],
        responses: [],
    },
    {
        id: 4,
        type: 'wordcloud',
        name: 'first',
        isQuestion: true,
        idExperience: 2817,
        questionNumber: 5,
        label: 'How are you feeling?',
        options: [],
        responses: [],
    },
    {
        id: 5,
        type: 'text',
        placeHolder: 'We love to hear about how you’re using the pack, or how we can make it better!',
        name: 'share',
        isQuestion: true,
        questionNumber: 6,
        label: "Anything else you'd like to share?",
        options: [],
        responses: [],
    },
    {
        id: 6,
        type: 'goodbye',
        placeHolder: '',
        name: 'goodbye',
        isQuestion: false,
        questionNumber: 7,
        label: '',
        options: [],
        responses: [],
    },

]

const defaultApiValues = {
    "idCustomer": 4,
    "idProduct": 690,
    "idCategory": 1054
}

let _SESSION = false;

const loadSession = async () => {
    if(_SESSION) return;
    _SESSION = true;
    let data = {
        "browser": browserName,
        "platform": osName,
        "url": window.location.host,
        urlHash: window.location.hash
    }
    return await PostData(`${BASE_URL}api-app/session`, data);
}

export const QuestionContextProvider = (props) => {
    const [questions, setQuestions] = useState(defaultQuestions);
    const [authToken, setAuthToken] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [responses, setResponses] = useState([]);
    const [comment, setComment] = useState("");
    const [history, setHistory] = useState([0])
    useEffect(()=>{
        loadSession().then((result)=>{
            setQuestions(result.data.questions);
            setAuthToken(result.data.token.authToken);
        })
    }, []);

    return (
        <QuestionsContext.Provider
            value={{
                questions,
                pageNumber,
                setPageNumber,
                responses,
                setResponses,
                history,
                setHistory,
                comment,
                setComment,
                authToken
            }}
        >
            {props.children}
        </QuestionsContext.Provider>
    );}

const getRatings = (questions) => {
    let _ratings = {};
    questions.filter(q=>q.isQuestion).forEach((q)=>{
            q.options.forEach(r=>_ratings[r.id]=r)
        }
    )
    return _ratings;
}
export const useQuestionContext = () => {
    const {
        questions,
        pageNumber,
        setPageNumber,
        responses,
        setResponses,
        history,
        setHistory,
        comment,
        setComment,
        authToken
    } = useContext(QuestionsContext);
    const [activeQuestion, setActiveQuestion] = useState(questions[0])
    const [isSaving, setIsSaving] = useState(false)

    useEffect(()=>{
        setActiveQuestion(questions[pageNumber])
    },[pageNumber, questions])

    useEffect(()=>{
    }, [responses])

    useEffect(()=>{
        console.log('next: history', history)
    }, [history])


    const last = (question, response) => {
        setIsSaving(true);
        saveResponses().then(result=>{
            setIsSaving(false);
            next(question, response);
        });
    }

    const getOptions = (ids) => {
        let options = [];
        if(!ids||ids.length===0) return [];
        ids = ids.map(a=>Number(a));
        questions.forEach(q=>{
            q?.options.forEach(opt=>{
                if(ids.includes(opt.id)) {
                    options.push(opt);
                }
            })
        })
        return options;
    }

    // Loops through all responses and matching forFor questionOption.ref to see if there's a match;
    const hasForkMatch = (currentResponses, forkOn) => {
        if(!forkOn || forkOn.length === 0 ) return false;
        let refs = currentResponses.map(a=>a.ref);
        console.log(refs);
        let found = false;
        forkOn.forEach(a=>{
            if(!found && refs.includes(a)) found=true;
        })
        return found;
    }

    const next = (question, response, text) => {
        let next_page = Math.min(pageNumber + 1, questions.length - 1);
        console.log('response', response);
        if(question?.type === 'text' && text) {
            PostData(`${BASE_URL}api-app/response`, {questionRef: question.ref, text}, authToken);
        }
        else if (response && response.length > 0) {
            PostData(`${BASE_URL}api-app/response`, {
                questionRef: question.ref, responses: response.map(a => Number(a))
            }, authToken);
        }

        // save all questionOption into responses
        let options = getOptions(response);
        let _responses = responses || [];
        options.forEach(a=>_responses.push(a));
        setResponses(responses);

        // find next question
        let found_question = null;
        while(found_question===null) {
            console.log('next', next_page, questions[next_page].text, {isFork: questions[next_page].isFork, forkOn: questions[next_page].forkOn})
            if(questions[next_page]?.isFork) {
                if(hasForkMatch(_responses, questions[next_page].forkOn)) {
                    found_question = questions[next_page];
                } else {
                    next_page++;
                }
            } else {
                found_question = questions[next_page];
            }
        }
        setHistory([...history, next_page])
        setPageNumber(next_page);
    }

    const nextOld = (question, response) => {
        let next_page = Math.min(pageNumber+1, questions.length-1);
        console.log('response', response);
        if(response && response.length>0) {
            PostData(`${BASE_URL}api-app/response`, {
                questionRef: question.ref, responses: response.map(a=>Number(a))}, authToken);
        }
        if(question && question.idExperience && response) {
            let _responses = responses;
            _responses[question.idExperience] = response;
            setResponses(_responses)
            console.log('next: question', next_page, questions[next_page].forkFor, questions[next_page].forkFor === question.id)
            if(response.length > 0 && question.type === 'radio' && questions[next_page].forkFor === question.id) {
                let found = null;
                let ratings = getRatings(questions);
                let rating = ratings[Number(response[0])];
                console.log('next: rating', rating);
                if(rating) {
                    let value = rating.value;
                    console.log('next: value', value);
                    for(let i = next_page * 1; i < questions.length && !found; i++ ) {
                        console.log('next: forkValues', next_page, questions[i].forkValues);
                        found = !questions[i].forkFor ||
                            (questions[i].forkFor === question.id && questions[i].forkValues.includes(value));
                        if(!found) next_page++;
                    }
                }
            } else if(questions[next_page].forkFor && questions[next_page].forkFor !== question.id) {
                next_page++;
            }
        }
        console.log('next: page: done', next_page, questions[next_page].label);
        setHistory([...history, next_page])
        setPageNumber(next_page);
    }

    const back = () => {
        let _history = history;
        _history.pop();
        setHistory(history);
        setPageNumber(Math.max(_history[_history.length-1], 0));
    }

    const saveResponses = async() => {
        await postResponses(questions, responses, comment);
    }

    return {
        questions,
        pageNumber,
        next,
        back, activeQuestion,
        questionCnt: questions.filter(a=>a.isQuestion).length,
        responses,
        setResponses,
        saveResponses,
        comment,
        setComment,
        last,
        isSaving,
    }
}

const postResponses = async(questions, responses, comment) => {
    const ip_response = await axios.get('https://geolocation-db.com/json/')
    console.log('postResponses: ip', ip_response.data)

    let ratings = {};
    questions.forEach(q=>{
        if(q.isQuestion && q.idExperience) {
            q.options.forEach(r=>ratings[r.id]=r);
        }
    })

    let experiences = [];
    Object.values(responses).forEach(value=>{
        value.forEach(ratingId=>{
            let rating = ratings[Number(ratingId)];
            console.log('rating', rating);
            if(rating) {
                experiences.push(
                    {
                        "id": 0,
                        "idExperience": rating.idExperience,
                        "idRatings": rating.id,
                        "ratingsValue": rating.value
                    }
                )
            }
        })
    });

    let data =
    {
      "id": 0,
      "idCustomer": defaultApiValues.idCustomer,
      "idProduct": defaultApiValues.idProduct,
      "age": "",
      "gender": "",
      "ip": "remoteip",
      "browser": browserName,
      "platform": osName,
      "images": "",
      "url": window.location.href,
      "registrationDate": dayjs().format(),
      "categories": [
        {
          "id": 0,
          "idCategory": defaultApiValues.idCategory,
          "like": "",
          "disLike": "",
          "comment": comment,
          "experiences": experiences
        }
      ]
    };
    let config = {
        method: 'post',
        url: 'https://burst-main-service.letsburst.com/api/Response',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
    };
    let result = await axios(config);
    console.log(result);

}

const PostData = async (url, data, authToken) => {
    let headers = {
        'Content-Type': 'application/json'
    };
    if(authToken) {
        headers.Session = authToken
    }
    let config = {
        method: 'post',
        url: url,
        headers: headers,
        data: JSON.stringify(data)
    };
    console.log('PostData', config);
    let result = await axios(config);
    console.log('PostData: data', result?.data);
    return result;
}
