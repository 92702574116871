import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";

const getStyle = (word, checked) => {
    let length = 0;
    word.split(' ').forEach(w => length = Math.max(length, w.length));
    if (word.split(' ').length > 3) length += 8
    let width = 50 + ((length - 4) * (4));
    if (checked) return {height: width, width, backgroundColor: '#FFC629'}
    return {height: width, width}
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export const getWords = (length, words, previouslyUsed = []) => {
    console.log('getWords', {words: words.length, used: previouslyUsed.length})
    let unused = words.filter(a => !previouslyUsed.includes(a))
    let newWords = [];
    for (let i = 0; i < length && i < unused.length; i++) {
        newWords.push(unused[getRandomInt(unused.length - 1)]);
        unused = unused.filter(a => !newWords.includes(a))
    }
    return newWords;
}

const buildResponseIndex = (options => {
    let a = {};
    options.forEach(o => a[o.id] = false);
    return a;
})

export const WordCloud = props => {
    const [responses, setResponses] = useState(buildResponseIndex(props.question.options));
    const [words, setWords] = useState(getWords(9, props.question.options))
    const {next} = useQuestionContext();

    return (
        <>
            <div id="wordcloud" className="questionContainer"><
                h1>{props.question.text}</h1>
                <p className="info">Please select all that apply</p>
                <div className="flex-container">
                    {words.map(option => {
                        return (
                            <div style={getStyle(option.text, responses[option.id])}
                                 className="clickable"
                                 onClick={() => {
                                     setResponses({...responses, [option.id]: !responses[option.id]})
                                 }}
                            >
                                <span>{option.text}</span>
                            </div>)
                    })}
                </div>
                <button className="alpaca-link clickable" onClick={() => {
                    let _usedWords = props.question.options.filter(a => {
                        console.log('used_words', a.id, responses[a.id])
                        return responses[a.id]
                    })
                    setWords(getWords(9, props.question.options, _usedWords))
                }}>This is fun. More words please.</button>
                <div className="footer">
                    <div className="button-fixed clickable"
                         onClick={() => {
                             let response = [];
                             Object.entries(responses).forEach(entry => {
                                 const [key, value] = entry;
                                 if (value) response.push(key)
                             });
                             next(props.question, response);
                         }}>
                        <p>Submit</p>
                    </div>
                </div>
            </div>
        </>
    )
}


