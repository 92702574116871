import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";

const getIcon = (option) => {
    if(option.icon && option.icon.indexOf('http')>-1) {
        return <img style={{height: 24, width: 24, marginRight: 5}} src={option.icon} alt={""}/>
    }
    return null;
}

export const QuestionRadio = props => {
    const [response, setResponse] = useState(0);
    const {next} = useQuestionContext();
    const [warning, setWarning] = useState(null);

    console.log('QuestionRadio', props.question);
    return (
        <>
            <div className="questionContainer">
                <h1>{props.question.text}</h1>
                {props.question.options.map(option => {
                    return (
                        <div key={`options_${Math.random()}`} className="optionsBox clickable" onClick={()=>{
                            setResponse(option.id);
                        }}>
                            <p>{getIcon(option)}{option.text}</p>
                            <input
                                type='radio'
                                className="optionsBoxInput clickable"
                                checked={option.id === response}
                                onChange={()=>{

                                }}
                            />
                        </div>
                    )
                })}
                {warning}
            </div>
            <div className="footer">
                <div className="button-fixed clickable" onClick={() => {
                    if(response > 0) {
                        next(props.question, [response])}
                    else {
                        setWarning(<div className="info" style={{color: 'red'}}>Please select one option</div> )
                    }
                }}>
                    <p>Next</p>
                </div>
            </div>
        </>

    )
}
