import {Header} from "./components/header";
import {useQuestionContext} from "./context/QuestionsContext";
import {QuestionText} from "./components/questions/QuestionText";
import {Welcome} from "./components/welcome/Welcome";
import {GoodBye} from "./components/welcome/GoodBye";
import {WordCloud} from "./components/questions/WordCloud";
import {QuestionCheckbox} from "./components/questions/QuestionCheckbox";
import {QuestionRadio} from "./components/questions/QuestionRadio";
import {QuestionSelect} from "./components/questions/QuestionSelect";

function App() {
    const {activeQuestion} = useQuestionContext();

    console.log('App:activeQuestion', activeQuestion);

    if(activeQuestion.type==='welcome') {
        return (
            <div className="App">
                <div className="container">
                    <Welcome/>
                </div>
            </div>

        )
    }

    const question = activeQuestion.type === 'text'
        ? <QuestionText question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'wordcloud'
        ? <WordCloud question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'goodbye'
        ? <GoodBye/>
        : activeQuestion.type === 'checkbox'
        ? <QuestionCheckbox question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'select'
        ? <QuestionSelect question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : <QuestionRadio question={activeQuestion} key={`question_${activeQuestion.id}`}/>

    const header = activeQuestion.type === 'goodbye'
    ? <Header key={`progress_${activeQuestion.id}`} questionNumber={activeQuestion.questionNumber} progressMessage={'1,000% Complete!'}/>
    : <Header key={`progress_${activeQuestion.id}`} questionNumber={activeQuestion.questionNumber}/>


    return (
        <div className="App">
            <div className="container">
                <div className="container-decoration-a"/>
                <div className="container-decoration-b"/>
                {header}
                {question}
            </div>
        </div>
    );
}

export default App;
