import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";

const sortNames = (a, b) => {
    const nameA = a.text.toUpperCase(); // ignore upper and lowercase
    const nameB = b.text.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

const getUnique = (options) => {
    let data = [];
    options.forEach(a=>{
        let i = data.filter(b=>b.text.replace(' School','') === a.text.replace(' School',''))[0]||false;
        if(!i) data.push(a);
    });
    return data;
}

export const QuestionSelect = props => {
    const [response, setResponse] = useState(0);
    const {next} = useQuestionContext();

    console.log('QuestionRadio', props.question);
    return (
        <>
            <div className="questionContainer">
                <h1>{props.question.text}</h1>
                <select value={response} onChange={(event)=>setResponse(event.target.value)}>
                    <option>Please Select One</option>
                    {getUnique(props.question.options)
                        .sort(sortNames)
                        .map(option => {
                        return (
                            <option value={option.id}>{option.text}</option>
                        )
                    })}
                </select>
            </div>
            <div className="footer">
                <div className="button-fixed clickable" onClick={() => next(props.question, [response])}>
                    <p>Next</p>
                </div>
            </div>
        </>

    )
}
