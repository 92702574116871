import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";

const buildResponseIndex = (options=>{
    let a = {};
    options.forEach(o=>a[o.id]=false);
    return a;
})

export const QuestionCheckbox = props => {
    const [responses, setResponses] = useState(buildResponseIndex(props.question.options));
    const {next} = useQuestionContext();

    console.log('QuestionSelect', responses);
    return (
        <>
            <div className="questionContainer">
                <h1>{props.question.text}</h1>
                <p className="info">Please select all that apply</p>
                {props.question.options.map(option => {
                    return (
                        <div key={`options_${Math.random()}`} className="optionsBox clickable" onClick={()=>{
                            setResponses({...responses, [option.id]: !responses[option.id]})
                        }}>
                            <p>{option.text}</p>
                            <input
                                type='checkbox'
                                className="optionsBoxInput clickable"
                                checked={responses[option.id]}
                                onChange={()=>{

                                }}
                            />
                        </div>
                    )
                })}
            </div>
            <div className="footer">
                <div className="button-fixed clickable"
                     onClick={() => {
                        let response = [];
                        Object.entries(responses).forEach(entry=>{
                            const [key, value] = entry;
                            if(value) response.push(key)
                        });
                        next(props.question, response);
                    }}>
                    <p>Next</p>
                </div>
            </div>
        </>

    )
}
